<template>
    <div class="RaceScore">
        <el-page-header @back="goBack" :content="back" title=""></el-page-header>
        <el-tabs v-model="activeName">
            <el-tab-pane label="积分导入" name="1">
                <Bar1></Bar1>
            </el-tab-pane>
            <el-tab-pane label="排行榜" name="2">
                <Bar2></Bar2>
            </el-tab-pane>
            <el-tab-pane label="上传记录" name="3">
                <Bar3></Bar3>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import Bar1 from '../../components/ScoreUploader.vue'
    import Bar2 from '../../components/PlayerBoard.vue'
    import Bar3 from '../../components/ScoreBoardHistory.vue'

    export default {
        data() {
            return {
                back: this.$route.params.back ? this.$route.params.back:'积分管理',
                memu: this.$route.params.menu,

                activeName: '1',
                tab: {},
            }
        },
        components: {
            Bar1,
            Bar2,
            Bar3
        },
        mounted() {
        },
        methods: {
            goBack() {
                this.$router.push({name: 'Home'})
            },
        },
    };
</script>

<style lang="less" scoped>
    .RaceScore {
    }
</style>
