<template>
    <div class="ScoreUploader">
        <el-card class="box-card">
            <div slot="header" class="clearfix">

                <div class="btn2">
                    <span style="height: 40px; line-height: 40px; margin-left: 10px">榜单年度：</span>
                    <el-select v-model="scoreBoardYearId" placeholder="榜单年度" clearable filterable style="width: 100px" @change="loadBoardList">
                        <el-option
                                v-for="item in scoreBoardYearList"
                                :key="item.id"
                                :label="item.scoreBoardYear"
                                :value="item.id"
                        ></el-option>
                    </el-select>

                    <span style="height: 40px; line-height: 40px; margin-left: 10px">榜单：</span>
                    <el-select v-model="scoreBoardId" placeholder="榜单" clearable filterable style="width: 202px">
                        <el-option
                                v-for="item in scoreBoardList"
                                :key="item.id"
                                :label="item.scoreBoardYear + ' - '+ item.boardName"
                                :value="item.id"
                        ></el-option>
                    </el-select>

                    <span style="height: 40px; line-height: 40px; margin-left: 10px">赛事：</span>
                    <el-select v-model="competitionId" placeholder="赛事" clearable filterable  style="width: 202px">
                        <el-option
                                v-for="item in competitionList"
                                :key="item.id"
                                :label="item.id + ' - ' + item.name"
                                :value="item.id"
                        ></el-option>
                    </el-select>

                    <span style="height: 40px; line-height: 40px; margin-left: 10px">赛事分级：</span>
                    <el-select v-model="raceLevel" clearable placeholder="赛事分级" style="width: 100px">
                        <el-option
                                v-for="item in levelList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        ></el-option>
                    </el-select>

                    <el-upload :action="urls.boardScore" name="file" :headers="{
                                      Authorization: stores.state.token,
                                    }"
                               :data="{ scoreBoardId: this.scoreBoardId, competitionId: this.competitionId, raceLevel: this.raceLevel }"
                               :multiple="false"
                               :show-file-list="false"
                               :on-success="uploadFileSuccess"
                               :on-error="uploadFileError"
                               :before-upload="validateUploadParam"
                               list-type="文件类型列表"
                    >
                        <el-button style="height: 40px; margin-right: 10px" type="primary" plain>上传组别排行</el-button>
                    </el-upload>
                    <el-button style="height: 40px; margin-left: 10px" type="primary" plain @click="boardRefresh">刷新排行榜</el-button>
                    <el-button style="height: 40px; margin-left: 10px" type="primary" plain @click="loadBoardPlayerRecord">查询</el-button>
                    <el-button style="height: 40px; margin-left: 10px" type="danger" plain @click="resetBoardScore">删除 (按照榜单，赛事删除)</el-button>
                </div>
            </div>

            <div class="main-card">
                <el-table :data="boardPlayerRecordList" style="width: 100%" height="540"
                          :header-cell-style="{ textAlign: 'center' }" :cell-style="{ textAlign: 'center' }">
                    <el-table-column label="赛事">
                        <template slot-scope="scope">
                            <span>{{ scope.row.competitionId + ' - ' + scope.row.competitionName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="组别">
                        <template slot-scope="scope">
                            <span>{{ scope.row.groupName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="选手1">
                        <template slot-scope="scope">
                            <span>{{ scope.row.playerName1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="选手1证件">
                        <template slot-scope="scope">
                            <span>{{ scope.row.playerCert1 }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="选手2">
                        <template slot-scope="scope">
                            <span>{{ scope.row.playerName2 }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="选手2证件">
                        <template slot-scope="scope">
                            <span>{{ scope.row.playerCert2 }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="排名">
                        <template slot-scope="scope">
                            <span>{{ scope.row.ranking }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="本场积分">
                        <template slot-scope="scope">
                            <span>{{ scope.row.score }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="城市">
                        <template slot-scope="scope">
                            <span>{{ scope.row.cityName }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="代表队">
                        <template slot-scope="scope">
                            <span>{{ scope.row.teamName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button
                                    size="mini"
                                    @click="handleBoardPlayerRecordEdit(scope.$index, scope.row)">编辑
                            </el-button>
                            <el-button
                                    size="mini"
                                    type="danger"
                                    @click="handleBoardPlayerRecordDelete(scope.row.id)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="page-card">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCount"
                >
                </el-pagination>
            </div>
        </el-card>


        <el-dialog
                title="编辑"
                :visible.sync="dialogeditPlayerRecordFormVisible"
                width="60%"
                class="group"
                :close-on-click-modal="false">
            <el-form
                    ref="editPlayerRecordForm"
                    :model="editPlayerRecordForm"
                    label-width="200px"
                    :label-position="labelPosition"
                    :rules="rules">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="赛事:">
                            <span  style="width: 217px">{{editPlayerRecordForm.competitionName}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="组别:">
                            <span  style="width: 217px">{{editPlayerRecordForm.groupName}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="排名:">
                            <span  style="width: 217px">{{editPlayerRecordForm.ranking}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="本场积分:" prop="score">
                            <el-input v-model="editPlayerRecordForm.score" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="选手1:">
                            <el-input v-model="editPlayerRecordForm.playerName1" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="选手1证件:">
                            <el-input v-model="editPlayerRecordForm.playerCert1" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="选手2:">
                            <el-input v-model="editPlayerRecordForm.playerName2" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="选手2证件:">
                            <el-input v-model="editPlayerRecordForm.playerCert2" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="城市:">
                            <el-input v-model="editPlayerRecordForm.cityName" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="代表队:">
                            <el-input v-model="editPlayerRecordForm.teamName" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveBoardPlayerRecord">提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import raceScore from "../api/raceScore";
    import store from "../store";

    export default {
        data() {
            return {
                urls: raceScore.URLs,
                stores: store,

                total: 10.0,

                currentPage: 1,
                pageSize: 10,
                totalCount: 0,

                raceLevel: null,
                competitionId: null,
                scoreBoardId: null,
                scoreBoardYearId: null,

                scoreBoardYearList: [],
                scoreBoardList: [],
                competitionList: [],
                boardPlayerRecordList: [],
                labelPosition: "right",
                editPlayerRecordForm: {},
                dialogeditPlayerRecordFormVisible: false,
                rules: {
                    score: [
                        { required: true, message: "请输入本场积分", trigger: "blur" },
                        { pattern: /^[1-9][0-9]*$/, message: "请输入正确的积分", trigger: "blur" }
                    ],
                },
                levelList: [
                    {
                        value: 1,
                        label: "一级赛事",
                    },
                    {
                        value: 2,
                        label: "二级赛事",
                    },
                    {
                        value: 3,
                        label: "三级赛事",
                    }
                ],

            };
        },
        created() {
            this.loadBoardYearList();
            // this.loadBoardList();
            this.loadRaceList();
        },
        methods: {

            // exportTable() {
            // window.location.href =
            // "http://asset.idance5.com/download/template/competition/%E6%88%90%E7%BB%A9%E8%A1%A8.xls";
            // },
            //导入回调
            uploadFileSuccess(callBackData) {
                const {status_code, msg} = callBackData;
                console.log(callBackData);
                if (status_code === "200") {
                    this.$message({
                        type: "success",
                        message: "导入成功",
                    });
                    this.loadBoardPlayerRecord();
                } else if (status_code == "1002") {
                    this.$message({
                        type: "warning",
                        message: msg,
                    });
                }
            },
            uploadFileError(callBackData) {

                this.$message({
                    type: "error",
                    message: callBackData.msg,
                });
                console.log(callBackData);
            },
            validateUploadParam() {
              if (!this.scoreBoardId) {
                  this.$message({
                      type: "error",
                      message: "请选择榜单",
                  });
                  return false;
              }

                if (!this.competitionId) {
                    this.$message({
                        type: "error",
                        message: "请选择赛事",
                    });
                    return false;
                }

                if (!this.raceLevel) {
                    this.$message({
                        type: "error",
                        message: "请选择赛事级别",
                    });
                    return false;
                }

                return true;
            },
            async loadBoardYearList() {
                try {
                    const result = await this.$api.loadBoardYearList(
                        `currentPage=1&pageSize=50`
                    );

                    this.scoreBoardYearList = result.rows;

                } catch (error) {
                    console.log(error);
                }
            },
            //榜单列表
            async loadBoardList() {
                try {
                    const result = await this.$api.loadBoardList(
                        `currentPage=1&pageSize=50&search_EQ_scoreBoardYearId=` + this.scoreBoardYearId
                    );

                    this.scoreBoardList = result.rows;
                    this.scoreBoardId = null;

                } catch (error) {
                    console.log(error);
                }
            },
            async loadRaceList() {
                try {
                    const result = await this.$api.loadRaceList(
                        `currentPage=1&pageSize=50`
                    );

                    this.competitionList = result.rows;

                } catch (error) {
                    console.log(error);
                }
            },

            async loadBoardPlayerRecord() {

                try {
                    let paramString = `currentPage=${this.currentPage}&pageSize=${this.pageSize}`;
                    if (this.scoreBoardId) {
                        paramString += `&scoreBoardId=${this.scoreBoardId}`;
                    }
                    if (this.competitionId) {
                        paramString += `&competitionId=${this.competitionId}`;
                    }

                  const result = await this.$api.loadBoardPlayerRecord(
                      paramString
                  );

                  this.boardPlayerRecordList = result.rows;
                  this.totalCount = result.total;
                } catch (error) {
                  console.log(error);
                }
            },
            async boardRefresh() {

                try {
                    if (this.scoreBoardId) {
                        const result = await this.$api.boardRefresh(
                            `scoreBoardId=${this.scoreBoardId}`
                        );
                        this.$message({
                            type: "success",
                            message: result,
                        });
                    } else {
                        this.$message({
                            type: "error",
                            message: "请选择榜单",
                        });
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            async resetBoardScore() {

                try {
                    if (this.scoreBoardId && this.competitionId) {
                        const result = await this.$api.resetBoardScore(
                            `scoreBoardId=${this.scoreBoardId}&competitionId=${this.competitionId}`
                        );
                        this.$message({
                            type: "success",
                            message: result.data.msg,
                        });
                    } else {
                        this.$message({
                            type: "error",
                            message: "请选择榜单和赛事",
                        });
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            handleBoardPlayerRecordDelete(id) {
                this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(async () => {
                    try {
                        const result = await this.$api.deleteBoardPlayerRecord(`boardPlayerRecordId=${id}`);
                        if (result.data.status_code == "200") {
                            this.$message({
                                type: "success",
                                message: "删除成功",
                            });
                            await this.loadBoardPlayerRecord();
                        } else if (result.data.status_code == "1002") {
                            this.$message({
                                type: "warning",
                                message: result.data.msg,
                            });
                        }

                    } catch (error) {
                        console.log(error);
                    }
                });
            },
            handleBoardPlayerRecordEdit(index, row) {
                console.log(row);
                this.dialogeditPlayerRecordFormVisible = true;

                this.editPlayerRecordForm = JSON.parse(JSON.stringify(row));
                this.editPlayerRecordForm.updateTime = null;
                this.editPlayerRecordForm.createTime = null;

            },
            async saveBoardPlayerRecord() {
                try {

                    console.log(this.editPlayerRecordForm);

                    const result = await this.$api.saveBoardPlayerRecord(this.editPlayerRecordForm);
                    if (result.data.status_code == "200") {
                        this.$message({
                            type: "success",
                            message: "保存成功",
                        });
                        await this.loadBoardPlayerRecord();
                    } else if (result.data.status_code == "1002") {
                        this.$message({
                            type: "warning",
                            message: result.data.msg,
                        });
                    }

                } catch (error) {
                    console.log(error);
                }

                this.dialogeditPlayerRecordFormVisible = false;
            },

            handleSizeChange(val) {
                this.pageSize = val;
                this.currentPage = 1;
                this.loadBoardPlayerRecord();
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.loadBoardPlayerRecord();
                console.log(`当前页: ${val}`);
            },
        },
    };
</script>

<style lang="less">
    .ScoreUploader {
        .box-card {
            width: 100%;

            .clearfix {
                &:before,
                &:after {
                    display: table;
                    content: "";
                }

                &:after {
                    clear: both;
                }

                .btn1 {
                    display: flex;
                    // float: right;
                    .select1 {
                        width: 150px;
                        margin-right: 20px;
                    }

                    .whether {
                        display: inline-block;
                        height: 40px;
                        line-height: 40px;
                    }
                }

                .btn2 {
                    display: flex;
                }
            }

            .select2 {
                width: 100px;
                margin-right: 20px;
            }

            .btn2 {
                display: flex;
                margin: 20px 0;
            }

            .main-card {
                .payInfo {
                    margin: 10px 0;

                    .pay {
                        font-size: 20px;
                    }
                }
            }

            .page-card {
                margin: 30px 0;
                float: right;
            }

            .text {
                font-size: 14px;
            }

            .item {
                margin-bottom: 18px;
            }
        }

        .dialog-footer,
        .el-dialog__header {
            text-align: center;
        }

        .el-col-11 {
            width: 20%;
        }

        .el-col-2 {
            width: 1.3%;
        }
    }
</style>
