<template>
    <div class="ScoreBoardHistory">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span style="height: 40px; line-height: 40px; margin-left: 10px">积分上传记录：</span>
            </div>

            <div class="main-card">
                <el-table :data="scoreBoardHistoryList" style="width: 100%" height="540"
                          :header-cell-style="{ textAlign: 'center' }" :cell-style="{ textAlign: 'center' }">
                    <el-table-column label="榜单年属">
                        <template slot-scope="scope">
                            <span>{{ scope.row.scoreBoardYear }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="榜单">
                        <template slot-scope="scope">
                            <span>{{ scope.row.scoreBoardName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="赛事">
                        <template slot-scope="scope">
                            <span>{{ scope.row.competitionName }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="赛事级别">
                        <template slot-scope="scope">
                            <span>{{ scope.row.level }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="文件记录">
                        <template slot-scope="scope">
                            <a :href="scope.row.fileUrl" target="_blank">下载</a>
                        </template>
                    </el-table-column>

                    <el-table-column label="上传时间">
                        <template slot-scope="scope">
                            <span>{{ scope.row.createTime }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="page-card">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCount"
                >
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
    import store from "../store";

    export default {
        data() {
            return {
                memu:this.$route.params.menu,

                stores: store,

                currentPage: 1,
                pageSize: 10,
                totalCount: 0,

                scoreBoardHistoryList: [],
            };
        },
        created() {
            this.loadUploadHistory();
        },
        methods: {

            async loadUploadHistory() {

                try {
                    let paramString = `currentPage=${this.currentPage}&pageSize=${this.pageSize}`;
                    const result = await this.$api.loadUploadHistory(
                        paramString
                    );

                    this.scoreBoardHistoryList = result.rows;
                    this.totalCount = result.total;

                } catch (error) {
                    console.log(error);
                }
            },

            handleSizeChange(val) {
                this.pageSize = val;
                this.currentPage = 1;
                this.loadUploadHistory();
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.loadUploadHistory();
                console.log(`当前页: ${val}`);
            },
        },
    };
</script>

<style lang="less">
    .ScoreBoardHistory {
        .box-card {
            width: 100%;

            .clearfix {
                &:before,
                &:after {
                    display: table;
                    content: "";
                }

                &:after {
                    clear: both;
                }

                .btn1 {
                    display: flex;
                    // float: right;
                    .select1 {
                        width: 150px;
                        margin-right: 20px;
                    }

                    .whether {
                        display: inline-block;
                        height: 40px;
                        line-height: 40px;
                    }
                }

                .btn2 {
                    display: flex;
                }
            }

            .select2 {
                width: 100px;
                margin-right: 20px;
            }

            .btn2 {
                display: flex;
                margin: 20px 0;
            }

            .main-card {
                .payInfo {
                    margin: 10px 0;

                    .pay {
                        font-size: 20px;
                    }
                }
            }

            .page-card {
                margin: 30px 0;
                float: right;
            }

            .text {
                font-size: 14px;
            }

            .item {
                margin-bottom: 18px;
            }
        }

        .dialog-footer,
        .el-dialog__header {
            text-align: center;
        }

        .el-col-11 {
            width: 20%;
        }

        .el-col-2 {
            width: 1.3%;
        }
    }
</style>
