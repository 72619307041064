<template>
    <div class="PlayerBoard">
        <el-card class="box-card">
            <div slot="header" class="clearfix">

                <div class="btn2">
                    <span style="height: 40px; line-height: 40px; margin-left: 10px">榜单年度：</span>
                    <el-select v-model="scoreBoardYearId" placeholder="榜单" clearable filterable style="width: 80px" @change="loadBoardList">
                        <el-option
                                v-for="item in scoreBoardYearList"
                                :key="item.id"
                                :label="item.scoreBoardYear"
                                :value="item.id"
                        ></el-option>
                    </el-select>

                    <span style="height: 40px; line-height: 40px; margin-left: 10px">榜单：</span>
                    <el-select v-model="scoreBoardId" placeholder="榜单" clearable filterable style="width: 202px">
                        <el-option
                                v-for="item in scoreBoardList"
                                :key="item.id"
                                :label="item.scoreBoardYear + ' - ' + item.boardName"
                                :value="item.id"
                        ></el-option>
                    </el-select>

                    <el-button style="height: 40px; margin-left: 10px" type="primary" plain @click="loadBoardPlayer">查询
                    </el-button>
                </div>
            </div>

            <div class="main-card">
                <el-table :data="boardPlayerList" style="width: 100%" height="540"
                          :header-cell-style="{ textAlign: 'center' }" :cell-style="{ textAlign: 'center' }">
                    <el-table-column label="选手1">
                        <template slot-scope="scope">
                            <span>{{ scope.row.playerName1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="选手1证件">
                        <template slot-scope="scope">
                            <span>{{ scope.row.playerCert1 }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="选手2">
                        <template slot-scope="scope">
                            <span>{{ scope.row.playerName2 }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="选手2证件">
                        <template slot-scope="scope">
                            <span>{{ scope.row.playerCert2 }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="总排名">
                        <template slot-scope="scope">
                            <span>{{ scope.row.totalRanking }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="总积分">
                        <template slot-scope="scope">
                            <span>{{ scope.row.totalScore }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="排名比例 (%)">
                        <template slot-scope="scope">
                            <span>{{ scope.row.rankingPercent / 100 }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="城市">
                        <template slot-scope="scope">
                            <span>{{ scope.row.cityName }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="代表队">
                        <template slot-scope="scope">
                            <span>{{ scope.row.teamName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button
                                    size="mini"
                                    @click="handleBoardPlayerEdit(scope.$index, scope.row)">编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="page-card">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCount"
                >
                </el-pagination>
            </div>
        </el-card>

        <el-dialog
                title="编辑"
                :visible.sync="dialogeditPlayerFormVisible"
                width="60%"
                class="group"
                :close-on-click-modal="false">
            <el-form
                    ref="editPlayerForm"
                    :model="editPlayerForm"
                    label-width="200px"
                    :label-position="labelPosition"
                    :rules="rules">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="总排名:">
                            <span  style="width: 217px">{{editPlayerForm.totalRanking}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="总积分:">
                            <el-input v-model="editPlayerForm.totalScore" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="选手1:">
                            <el-input v-model="editPlayerForm.playerName1" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="选手1证件:">
                            <el-input v-model="editPlayerForm.playerCert1" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="选手2:">
                            <el-input v-model="editPlayerForm.playerName2" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="选手2证件:">
                            <el-input v-model="editPlayerForm.playerCert2" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="城市:">
                            <el-input v-model="editPlayerForm.cityName" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="代表队:">
                            <el-input v-model="editPlayerForm.teamName" style="width: 217px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveBoardPlayer">提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import store from "../store";

    export default {
        data() {
            return {
                stores: store,

                currentPage: 1,
                pageSize: 10,
                totalCount: 0,

                scoreBoardId: null,
                scoreBoardYearId: null,
                labelPosition: "right",
                editPlayerForm: {},
                dialogeditPlayerFormVisible: false,
                rules: {
                    totalScore: [
                        { required: true, message: "请输入总积分", trigger: "blur" },
                        { pattern: /^[1-9][0-9]*$/, message: "请输入正确的积分", trigger: "blur" }
                    ],
                },
                scoreBoardYearList: [],
                scoreBoardList: [],
                boardPlayerList: [],
            };
        },
        created() {
            this.loadBoardYearList();
        },
        methods: {
            async loadBoardYearList() {
                try {
                    const result = await this.$api.loadBoardYearList(
                        `currentPage=1&pageSize=50`
                    );

                    this.scoreBoardYearList = result.rows;

                } catch (error) {
                    console.log(error);
                }
            },
            //榜单列表
            async loadBoardList() {
                try {
                    const result = await this.$api.loadBoardList(
                        `currentPage=1&pageSize=50&search_EQ_scoreBoardYearId=` + this.scoreBoardYearId
                    );

                    this.scoreBoardList = result.rows;
                    this.scoreBoardId = null;

                } catch (error) {
                    console.log(error);
                }
            },

            async loadBoardPlayer() {

                try {
                    let paramString = `currentPage=${this.currentPage}&pageSize=${this.pageSize}`;
                    if (this.scoreBoardId) {
                        paramString += `&scoreBoardId=${this.scoreBoardId}`;
                        const result = await this.$api.loadBoardPlayer(
                            paramString
                        );

                        this.boardPlayerList = result.rows;
                        this.totalCount = result.total;
                    } else {
                        this.$message({
                            type: "error",
                            message: "请选择榜单",
                        });
                        return false;
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            handleBoardPlayerEdit(index, row) {
                console.log(row);
                this.dialogeditPlayerFormVisible = true;
                this.editPlayerForm = JSON.parse(JSON.stringify(row));
            },
            async saveBoardPlayer() {
                try {
                    console.log(this.editPlayerForm);

                    const result = await this.$api.saveBoardPlayer(this.editPlayerForm);
                    if (result.data.status_code == "200") {
                        this.$message({
                            type: "success",
                            message: "保存成功",
                        });
                        await this.loadBoardPlayer();
                    } else if (result.data.status_code == "1002") {
                        this.$message({
                            type: "warning",
                            message: result.data.msg,
                        });
                    }

                } catch (error) {
                    console.log(error);
                }

                this.dialogeditPlayerFormVisible = false;
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.currentPage = 1;
                this.loadBoardPlayer();
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.loadBoardPlayer();
                console.log(`当前页: ${val}`);
            },
        },
    };
</script>

<style lang="less">
    .PlayerBoard {
        .box-card {
            width: 100%;

            .clearfix {
                &:before,
                &:after {
                    display: table;
                    content: "";
                }

                &:after {
                    clear: both;
                }

                .btn1 {
                    display: flex;
                    // float: right;
                    .select1 {
                        width: 150px;
                        margin-right: 20px;
                    }

                    .whether {
                        display: inline-block;
                        height: 40px;
                        line-height: 40px;
                    }
                }

                .btn2 {
                    display: flex;
                }
            }

            .select2 {
                width: 100px;
                margin-right: 20px;
            }

            .btn2 {
                display: flex;
                margin: 20px 0;
            }

            .main-card {
                .payInfo {
                    margin: 10px 0;

                    .pay {
                        font-size: 20px;
                    }
                }
            }

            .page-card {
                margin: 30px 0;
                float: right;
            }

            .text {
                font-size: 14px;
            }

            .item {
                margin-bottom: 18px;
            }
        }

        .dialog-footer,
        .el-dialog__header {
            text-align: center;
        }

        .el-col-11 {
            width: 20%;
        }

        .el-col-2 {
            width: 1.3%;
        }
    }
</style>
